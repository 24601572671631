import React, {useState} from "react";
import axios from 'axios';

import {Card, Table, Button, Tooltip} from 'antd'; // Import Table from antd
import {EditOutlined} from '@ant-design/icons';
import {PlusOutlined} from '@ant-design/icons';
// utils
import {BASE_URL} from 'utils/constants';
import {JsonSchemaItem, JsonSchemaListResponse} from "utils/interfaces";

import JsonSchemaListViewAddModal from "./JsonSchemaListComp/AddModal";
import JsonSchemaListViewEditModal from "./JsonSchemaListComp/EditModal";

import {
    useQuery,
    useQueryClient,
    useMutation,
} from '@tanstack/react-query';


const JsonSchemaListView: React.FC = () => {

    const queryClient = useQueryClient()

    const [showAddModal, setShowAddModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [jsonSchemaEdit, setJsonSchemaEdit] = useState<JsonSchemaItem>();

    const jsonSchemaListQuery = useQuery({
        queryKey: ['jsonSchemaList'],
        queryFn: async () => {
            const res = await axios.get<JsonSchemaListResponse>(`${BASE_URL}/get_json_schemas_base`)
            return res.data.content
        }
    })

    const deleteJsonSchema = useMutation({
        mutationFn: (id: number) => axios.post(`${BASE_URL}/delete_json_schema_base/${id}`),
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: ['jsonSchemaList'] })
          queryClient.invalidateQueries({ queryKey: ['baseFirmwareList'] })
          queryClient.invalidateQueries({ queryKey: ['vehicleList'] })
        }
    })

    const toggleEditModal = () => {
        setShowEditModal(!showEditModal)
    }

    const toggleAddModal = () => {
        setShowAddModal(!showAddModal)
    }

    // Columns definition for Ant Design Table
    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Created at',
            dataIndex: 'created_at',
            key: 'created_at',
        },
        {
            title: 'Schema Version Name',
            dataIndex: 'version_schema_name',
            key: 'version_schema_name',
        },
        {
            title: 'Json Config',
            dataIndex: 'json_schema',
            key: 'json_schema',
            render: (_text: any, record: any) => (
                <EditOutlined onClick={() => handleJsonSchemaEdit(record)}/>
            ),
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (_text: any, record: any) => (
                <div>
                    <Button danger onClick={() => deleteJsonSchema.mutate(record.id)}>Delete</Button>
                </div>
            ),
        },
    ];

    const handleJsonSchemaEdit = (record: JsonSchemaItem) => {
        setJsonSchemaEdit(record);
        console.log(record);
        toggleEditModal();
    };

    return (
        <div>
            <Card title={
                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    JSON Schema Management
                    <Tooltip title="Add New Schema">
                        <Button type="primary" shape="circle" icon={<PlusOutlined/>} onClick={toggleAddModal}/>
                    </Tooltip>
                </div>
            }>
                <JsonSchemaListViewEditModal jsonSchema={jsonSchemaEdit!} showModal={showEditModal} onClose={toggleEditModal}/>
                <JsonSchemaListViewAddModal showModal={showAddModal} onClose={toggleAddModal}/>
                <Table columns={columns} dataSource={jsonSchemaListQuery.data}/>
            </Card>
        </div>
        );
};

export default JsonSchemaListView;
