import React, {useState, useEffect, useRef} from "react";
import axios from 'axios';
import {Card, Checkbox, InputRef, Table, TableColumnType} from 'antd'; // Import Table from antd
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {SubmitHandler, useForm} from "react-hook-form";
import Select from "react-select";
// utils
import {
    CannyDevice,
    CannyIForm,
    StringSelectObject,
    FirmwareBase,
    CannyDeviceIndex,
} from 'utils/interfaces'
import {BASE_URL} from 'utils/constants';
import {FilterDropdownProps} from "antd/es/table/interface";
import Search from "antd/es/input/Search";
import {CloseOutlined, CommentOutlined, SearchOutlined} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import CommentsComponent from "../Components/CommentsComponent";
// components

axios.defaults.withCredentials = true


const CannyListPage: React.FC = () => {
    const [cannyDevices, setCannyDevices] = useState<CannyDevice[]>([]);
    const [selectedCanny, setSelectedCanny] = useState<CannyDevice | null>(null)
    const [firmwareVersions, setFirmwareVersions] = useState<FirmwareBase[]>()
    const [hardwareVersions, setHardwareVersions] = useState<StringSelectObject[]>([]);
    const [qcOptions] = useState<StringSelectObject[]>([
            {value: true, label: 'True'},
            {value: false, label: 'False'}]
        )
    ;
    const [cannyStates, setCannyStates] = useState<StringSelectObject[]>([]);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showAddModal, setShowAddModal] = useState(false);

    // const [cannyItemsInBatch, setNewCannyItemsInBatch] = useState<Number>(1)
    // const [cannyBatchHWVersion, setCannyBatchHWVersion] = useState<String>("")
    // const [cannyBatchFWVersion, setCannyBatchFWVersion] = useState<String>("")

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef<InputRef>(null);

    const [showCommentModal, setShowCommentModal] = useState(false);
    const [selectedCannyId, setSelectedCannyId] = useState(0);

    const openCommentModal = (canny:CannyDevice) => {
        setSelectedCanny(canny);
        setSelectedCannyId(canny.id);
        setShowCommentModal(true);
    }
    const closeCommentModal= () => {
        setShowCommentModal(false);
    }
    const {
        register,
        handleSubmit,
        formState: {errors},
        reset
    } = useForm<CannyIForm>()

    const {
        handleSubmit: cannyEditSubmit,
        setValue: cannyEditSetValue,
        reset: cannyEditReset,
    } = useForm<CannyDevice>();

    const onSubmit: SubmitHandler<CannyIForm> = data => {
        console.log(data);
        axios.post(`${BASE_URL}generate_canny`, {
            "no_items_in_batch": data.items_in_batch,
            // "firmware_version": data.firmware_version?.name,
            "hardware_version": data.hardware_version,
        }).then(response => {
            console.log(response.data)
            updateCannyDevices();
            toggleAddModal();
            //todo add to use state as reset if for submission good
            reset();
        }).catch(error => {
            console.error('Error adding Canny Devices:', error);
        });

    }
    const updateCanny: SubmitHandler<CannyDevice> = data => {
        console.log("UPDATE API CALL")
        console.log(data)
        axios.post(`${BASE_URL}canny_update_device`, {
            "serial_no": data.serial_no,
            // temp fix until the firmware page is there
            "firmware_base_id": data?.firmware_version?.id,
            // "firmware_version_name": data?.firmware_version?.version_name,
            "hardware_version": data?.hardware_version,
            "qc_pass": data?.qc_pass,
            "status": data?.status
        }).then(response => {
            updateCannyDevices();
            toggleEditModal();
        }).catch(error => {
            console.error('Error fetching Users:', error);
        });
    }

    const handleSearch = (
        selectedKeys: string[],
        confirm: FilterDropdownProps['confirm'],
        dataIndex: CannyDeviceIndex,
    ) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters: (() => void), close: ()=> void) => {
        clearFilters();
        setSearchText('');

        close();
    };

    const getColumnSearchProps = (dataIndex: CannyDeviceIndex): TableColumnType<CannyDevice> => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                <Search
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onSearch={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'flex' }}
                    allowClear={{ clearIcon: <CloseOutlined onClick={()=> clearFilters && handleReset(clearFilters,close) } /> }}                />

            </div>
        ),
        filterIcon: (filtered: boolean) => (
            <>
                <SearchOutlined style={{color: filtered ? '#1677ff' : undefined}} />
            </>
        ),
        onFilter: (value, record) =>
            // @ts-ignore
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes((value as string).toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const toggleEditModal = () => {
        setShowEditModal(!showEditModal)
    }
    const toggleAddModal = () => {
        setShowAddModal(!showAddModal)
    }
    useEffect(() => {
        // Fetch canny devices from api
        updateFirmwareVersions()
        updateCannyDevices();
        updateHardwareVerions();
        updateCannyStates();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Columns definition for Ant Design Table
    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Created at',
            dataIndex: 'created_at',
            key: 'created_at',
            sorter: (a:CannyDevice, b:CannyDevice) => a.created_at.localeCompare(b.created_at),
            ...getColumnSearchProps("created_at")

        },
        {
            title: 'Firmware Version',
            key: 'firmware_version.version_name',
            render: (_text: any, record: any) => (
                renderFirmwareVersionName(record)
            ),
            ...getColumnSearchProps("firmware_version")

        },
        {
            title: 'Hardware Version',
            dataIndex: 'hardware_version',
            key: 'hardware_version',
            ...getColumnSearchProps("hardware_version")
        },
        {
            title: 'MAC Address',
            dataIndex: 'mac_address',
            key: 'mac_address',
            ...getColumnSearchProps("mac_address")
        },
        {
            title: 'PIN',
            dataIndex: 'pin',
            key: 'pin',
            ...getColumnSearchProps("pin")
        },
        {
            title: 'QC',
            dataIndex: 'qc_pass',
            key: 'qc_pass',
            render: (value: boolean) => renderBoolean(value)
        },
        {
            title: 'Serial No.',
            dataIndex: 'serial_no',
            key: 'serial_no',
            ...getColumnSearchProps("serial_no")
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            ...getColumnSearchProps("status")
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (_text: any, record: any) => (
                <div>
                    <Button onClick={() => handleEdit(record)}>Edit</Button>
                    <Button onClick={() => handleDeleteCanny(record.id)}>Delete</Button>
                </div>
            ),
        },
        {
            title:'',
            key: 'comments',
            render: (_text: any, record: any) => (
                <div>
                    <CommentOutlined style={{fontSize:20}} onClick={()=>openCommentModal(record)}  />
                </div>
            ),

        }
    ];

    const renderFirmwareVersionName = (record:any) => {
        // console.log("RECORDID",record)
        const name = firmwareVersions?.find(item => item.id === record.firmware_base_id)
        // console.log("NAME IS", name?.version_name)
        return (
            <div>
                {name?.version_name}
            </div>
        )

    }

    const renderBoolean = (value: boolean) => {
        return (
            <Checkbox checked={value}></Checkbox>
        );
    }
    const updateCannyDevices = () => {
        axios.get(`${BASE_URL}get_canny_devices`).then(response => {
            setCannyDevices(response.data.content)
        }).catch(error => {
            console.error('Error fetching Canny Devices:', error);
        });
    }

    const updateHardwareVerions = () => {
        axios.get(`${BASE_URL}canny_get_hw`)
            .then(response => {
                // Map the array of strings to an array of objects with 'value' and 'label' properties
                const formattedVersions = response.data.content.map((version: any) => ({
                    value: version,
                    label: version
                }));
                // Set the state with the formatted array
                setHardwareVersions(formattedVersions);
            })
            .catch(error => {
                console.error('Error fetching Canny Devices:', error);
            });
    };

    const updateCannyStates = () => {
        axios.get(`${BASE_URL}get_canny_states`)
            .then(response => {
                // Map the array of strings to an array of objects with 'value' and 'label' properties
                const formattedVersions = response.data.content.map((version: any) => ({
                    value: version,
                    label: version
                }));
                // Set the state with the formatted array
                setCannyStates(formattedVersions);
            })
            .catch(error => {
                console.error('Error fetching Canny Devices:', error);
            });
    };

    const updateFirmwareVersions = () => {
        axios.get(`${BASE_URL}get_firmware_bases`).then(response => {
            const fw_versions = response.data.content
            console.log(fw_versions)
            setFirmwareVersions(fw_versions)
            if (fw_versions.length > 0) {
                console.log(fw_versions)
            }
        }).catch(error => {
            console.error('Error fetching Canny Devices:', error);
        });
    }

    const downloadExcel = () => {
        axios({
            url: `${BASE_URL}/get_printable_devices`, //your url
            method: 'GET',
            responseType: 'blob', // important
        }).then((response) => {
            // create file link in browser's memory
            const href = URL.createObjectURL(response.data);

            // create "a" HTML element with href to file & click
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', 'labels.xlsx'); //or any other extension
            document.body.appendChild(link);
            link.click();

            // clean up "a" element & remove ObjectURL
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
            updateCannyDevices()
        });
    }
    const handleEdit = (cannyDevice: CannyDevice) => {
        // Handle edit action, you can console log or implement your logic here
        // cannyEditReset(cannyDevice)
        cannyEditReset()
        setSelectedCanny(cannyDevice)
        updateHardwareVerions()
        // console.log(cannyDevice)
        console.log("CALL HANDLE EDIT")
        console.log(cannyDevice)
        cannyEditSetValue("serial_no", cannyDevice.serial_no)
        // updateHardwareVerions()
        toggleEditModal()
    };

    const handleDeleteCanny = (cannyID: number) => {
        // Handle delete action, you can console log or implement your logic here
        console.log('Delete Canny with ID:', cannyID);
        axios.post(`${BASE_URL}delete_canny/${cannyID}`).then(response => {
            updateCannyDevices();
        }).catch(error => {
            console.error('Error fetching Canny device:', error);
        });
    };

    // @ts-ignore
    return (
        <div>
            <CommentsComponent showModal={showCommentModal} onClose={closeCommentModal} commentTarget={"CANNY"} targetId={selectedCannyId} targetObject={selectedCanny}></CommentsComponent>

            <Card title="Canny Device Management">
                <Button onClick={toggleAddModal}>Add Canny Devices</Button>
                <Button variant="primary" onClick={downloadExcel}>
                    Download excel
                </Button>
                <Modal show={showAddModal} onHide={toggleAddModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>New Canny Device Batch</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form>
                            <label>Number of items in batch</label>
                            <input defaultValue="" {...register("items_in_batch", {
                                required: true,
                                valueAsNumber: true
                            })} />
                            {errors.items_in_batch && <span>This field is required</span>}
                            <label>Hardware version</label>
                            <input defaultValue="" {...register("hardware_version", {required: true})} />
                            {errors.hardware_version && <span>This field is required</span>}
                        </form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={toggleAddModal}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={handleSubmit(onSubmit)}>
                            Save Changes
                        </Button>
                    </Modal.Footer>
                </Modal>
                {/*@ts-ignore*/}
                <Table columns={columns} dataSource={cannyDevices} showSorterTooltip={{ target: 'sorter-icon' }}/>
                <Modal show={showEditModal} onHide={toggleEditModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Editing access for {selectedCanny?.serial_no}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form>
                            <label>Select Firmware Version</label>
                            <Select
                                isClearable
                                defaultValue={firmwareVersions?.find(element => {
                                    console.log("ELEMENT IS",element)
                                    console.log("SELECTEDCANNY IS",selectedCanny)
                                    return element.id === selectedCanny?.firmware_base_id
                                })}
                                styles={{menuPortal: (base) => ({...base, zIndex: 9999})}}
                                menuPosition={'fixed'}
                                menuPlacement={'bottom'}
                                isSearchable
                                name="color"
                                menuShouldScrollIntoView={false}
                                options={firmwareVersions}
                                getOptionLabel={(option) => option.version_name}
                                getOptionValue={(option) => option.version_name}
                                onChange={(selectedOption) =>
                                    cannyEditSetValue("firmware_version", selectedOption as FirmwareBase)
                                }
                            />
                            <label>Select Hardware Version</label>
                            <Select
                                isClearable
                                defaultValue={hardwareVersions.find(hardwareVersion => hardwareVersion.value === selectedCanny?.hardware_version)}
                                styles={{menuPortal: (base) => ({...base, zIndex: 9999})}}
                                menuPosition={'fixed'}
                                menuPlacement={'bottom'}
                                isSearchable
                                name="color"
                                menuShouldScrollIntoView={false}
                                options={hardwareVersions}
                                onChange={(selectedOption) => selectedOption && cannyEditSetValue("hardware_version", selectedOption.value as string)}

                            />
                            <label>Select Canny QC status</label>
                            <Select
                                styles={{menuPortal: (base) => ({...base, zIndex: 9999})}}
                                defaultValue={qcOptions.find(qcOption => qcOption.value === selectedCanny?.qc_pass)}
                                menuPosition={'fixed'}
                                menuPlacement={'bottom'}
                                isSearchable
                                name="color"
                                menuShouldScrollIntoView={false}
                                options={qcOptions}
                                onChange={(selectedOption) => selectedOption && cannyEditSetValue("qc_pass", selectedOption.value as boolean)}

                            />
                            <label>Canny State</label>
                            <Select
                                styles={{menuPortal: (base) => ({...base, zIndex: 9999})}}
                                defaultValue={cannyStates.find(state => state.value === selectedCanny?.status)}
                                menuPosition={'fixed'}
                                menuPlacement={'bottom'}
                                isSearchable
                                name="color"
                                menuShouldScrollIntoView={false}
                                options={cannyStates}
                                onChange={(selectedOption) => selectedOption && cannyEditSetValue("status", selectedOption.value as string)}
                            />
                        </form>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={toggleEditModal}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={cannyEditSubmit(updateCanny)}>
                            Save Changes
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Card>
        </div>


    );


};

export default CannyListPage;